<template>
    <div class="contain" v-infinite-scroll="load" :infinite-scroll-disabled="finish">
        <searchBar :searchInput="false"></searchBar>
        <el-button-group class="mb_15">
            <el-button type="primary" size="large">管理任务</el-button>
            <el-button size="large" @click="onTask">
                打款管理
            </el-button>
        </el-button-group>
        <div class="title bgf flex">
            <!-- <div class="fs_18 color_333 flex_start">
                <span>{{ reward_name }}</span>
            </div> -->
            <div class="flex_col body">
                <div class="flex_c mb_10">
                    <p class="fs_16 bold color_333 mr_15">{{ reward_name }}</p>
                    <p class="fs_12 color_999">任务编号 {{ detail.reward_no }}</p>
                </div>
                <div class="flex">
                    <div class="use_tip color_333">
                        <span class="line color_red">{{ detail.settle_info }}</span>
                        <span class="line">{{ detail.price_type }}</span>
                        <span class="line">{{ detail.settle_type }}</span>
                        <span class="line">{{ detail.publish_user_name }}发布</span>
                        <!-- <span class="line" @click="onTask">打款管理</span> -->
                    </div>
                </div>
            </div>
            <div class="btn fs_14 flex_cen color_999 import-batch" @click="authPath([], bulkImport)">
                <p class="import-icon"></p>批量导入 <span class="tip">NEW</span>
            </div>
            <!-- <router-link to="/employment/post" class="btn flex_cen">
                <img src="@/assets/imgs/icon/post-icon.png" class="post-icon" alt="" />
                <span class="fs_14 color">发布任务</span>
            </router-link> -->
        </div>
        <div class="subtitle flex">
            <div class="flex_c">
                <div class="button color_999 fs_14 flex_cen btn mr_10" :class="{ active: tab === 0 }"
                    @click.stop="changeTab(0)" v-if="false">
                    <span class="center">待审核 ({{ count.status0 || 0 }})</span>
                </div>
                <div class="button color_999 fs_14 flex_cen btn mr_10" :class="{ active: tab === 1 }"
                    @click.stop="changeTab(1)">
                    <span class="center">进行中 ({{ count.status1 || 0 }})</span>
                </div>
                <div class="button color_999 fs_14 flex_cen btn mr_10" :class="{ active: tab === 2 }"
                    @click.stop="changeTab(2)">
                    <span class="center">已结束 ({{ count.status2 || 0 }})</span>
                </div>
                <div class="button color_999 fs_14 flex_cen btn mr_10" :class="{ active: tab === -1 }"
                    @click.stop="changeTab(-1)" v-if="false">
                    <span class="center">已拒绝 ({{ count.status_1 || 0 }})</span>
                </div>
            </div>
            <div>
                <el-input placeholder="输入搜索关键词" class="fs_14 " ref="_search" v-model.trim="keywords" @keyup="search">
                    <template #suffix>
                        <i class="el-icon-search btn" @click="handleClick"></i>
                    </template></el-input>
            </div>
        </div>
        <div class="mt_15">
            <!-- 待审核 -->
            <checking :list="list" v-if="tab === 0"></checking>
            <!-- 进行中 -->
            <conduct :list="list" v-else-if="tab === 1"></conduct>
            <!-- 已结束 -->
            <over :list="list" v-else-if="tab === 2"></over>
            <!-- 已拒绝 -->
            <reject :list="list" v-else-if="tab === -1"></reject>
        </div>

        <empty :finish="finish" :length="list.length" v-if="list.length == 0"></empty>
    </div>
</template>

<script>
import checking from "./components/checking"
import conduct from "./components/conduct"
import over from "./components/over"
import reject from "./components/reject"
import { s_getItem } from "@/common/cache"
export default {
	name: "EmploymentList",
	components: { checking, conduct, over, reject },
	data() {
		return {
			reward_name: "",
			id: null,
			detail: {},
			count: {},
			list: [],
			page: 1,
			limit: 10,
			finish: false,
			keywords: "",
			tab: 1
		}
	},
	created() {
		this.id = this.$route.params.id
		this.reward_name = this.$route.params.name
		this.detail = s_getItem('_taskDetail')
		//获取父级传入tab
		if (this.$route.query.tab) this.tab = parseInt(this.$route.query.tab)
		this.getCount()
		this.getList()
	},
	methods: {
		load() {
			this.page++
			this.getList()
		},
		init() {
			this.page = 1
			this.list = []
			this.finish = false
			this.getList()
		},
		getCount() {
			this.$axios({
				url: "/ent/v3/apply/total",
				method: "GET",
				data: {
					reward_id: this.id
				}
			}).then(res => {
				if (res.code === 200) {
					this.count = res.data
				}
			})
		},
		getList() {
			this.$axios({
				url: `/ent/v3/apply`,
				method: "GET",
				data: {
					keywords: this.keywords,
					reward_id: this.id,
					status: this.tab,
					page: this.page,
					limit: this.limit
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = this.list.concat(res.data.list)
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		bulkImport() {
			this.$router.push(`/bulkImport/reward?reward_id=${this.id}`)
		},
		handleDialog(dialog) {
			this.$refs[dialog].dialogVisible = true
		},
		search(e) {
			if (e.keyCode === 13) {
				var input = this.$refs._search
				input.blur()
				this.init()
			}
		},
		handleClick() {
			if (!this.keywords) return
			this.init()
		},
		changeTab(v) {
			if (this.tab === v) return
			this.tab = v
			this.init()
		},
		onTask(){
			this.$router.push(`/special/batch/${this.id}/${this.reward_name}`)
		}
	}
}
</script>
<style lang="less" scoped>
.title {
    margin-bottom: 20px;
}

.color_red {
    color: #ff4e4e;
}

/deep/.subtitle {
    .el-input__inner {
        width: 340px;
        height: 40px;
        border: none;
        padding-right: 40px;
    }

    .el-input__suffix {
        right: 15px;
    }

    .el-icon-search {
        font-size: 20px;
    }
}

.el-icon-search {
    line-height: 40px;
}

.button {
    width: 130px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 25px;

    &.active {
        border-color: #1890ff;
        color: #1890ff;
    }
}

.bottom {
    position: relative;
}

.edit {
    background-color: #1890ff;
    color: #ffffff;
    border-radius: 0px 0px 15px 15px;
    text-align: center;
    width: 66px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 0;
    right: 30px;
}

.disable {
    background-color: #ededed;
    color: #999;
}

.title {
    padding: 19px 38px 20px 30px;
    border-radius: 2px;

    .post-icon {
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
}
</style>
