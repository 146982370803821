<template>
    <div class="list">
        <div class="item bgf mt_10" v-for="(item, index) in list" :key="index">
            <div class="bottom_link top flex_end">
                <div class="flex_col body" v-if="false">
                    <div class="flex_c mb_10">
                        <p class="fs_16 bold color_333 mr_15">{{ item.reward.reward_name }}</p>
                        <p class="fs_12 color_999">任务编号 {{ item.reward.reward_no }}</p>
                    </div>
                    <div class="flex">
                        <div class="use_tip color_333">
                            <span class="line color_red">{{ item.reward.settle_info }}</span>
                            <span class="line">{{ item.reward.price_type }}</span>
                            <span class="line">{{ item.reward.settle_type }}</span>
                            <span class="line">{{ item.reward.publish_user_name }}发布</span>
                        </div>
                        <div class="sub_color fs_12">接单人</div>
                    </div>
                </div>
                <div class="flex_end flex_1">
                    <div class="fs_12 wait flex_cen ml_20 mark_body">
                        <div class="color fs_20 name">{{ item.user&&item.user.realname || '未知' }}</div>
                        <div class="mark_img">
                            <el-image class="img_cont" :src="mark"></el-image>
                        </div>
                    </div>
                    <div class="flex_c">
                        <div class="btn fs_14 center color check" @click="runRecord(item)">经营日志</div>
                        <router-link :to="`/employment/pay-list/${item.id}/${item.reward_id}/2`" class="btn fs_14 center color check">支付记录</router-link>
                        <div class="btn fs_14 center color_999" @click="delel(item, index)">删 除</div>
                    </div>
                </div>
            </div>
            <div class="bottom fs_14">
                <el-row :gutter="15">
                    <el-col :span="7" class="flex_c">
                        <span class="label sub_color">主体名称：</span>
                        <span class="color_333 flex_1 one">{{ item.user_bussiness && item.user_bussiness.company_name || '-' }}</span>
                    </el-col>
                    <el-col :span="7">
                        <span class="label sub_color">信用代码：</span>
                        <span class="color_333">{{ item.user_bussiness && item.user_bussiness.credit_code || '-' }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">已付金额：</span>
                        <span class="color_333">{{ item.income_total_amount }} 元</span>
                    </el-col>
                    <el-col :span="4">
                        <span class="label sub_color">已付次数：</span>
                        <span class="color_333">{{ item.income_total_amount_count }}次</span>
                    </el-col>
                </el-row>
                <el-row :gutter="15" class="mt_10">
                    <el-col :span="7">
                        <span class="label sub_color">申请人电话：</span>
                        <span class="color_333">{{ item.user&&item.user.mobile || '暂无' }}</span>
                    </el-col>
                    <el-col :span="7">
                        <span class="label sub_color">申请编号：</span>
                        <span class="color_333">{{ item.apply_no }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">申请时间：</span>
                        <span class="color_333">{{ item.create_time }}</span>
                    </el-col>
                    <el-col :span="4">
                        <p class="fs_14 color preview btn" @click="userSign(item.agreement)">用户签署协议</p>
                    </el-col>
                </el-row>
            </div>
        </div>
        <el-dialog v-model="agreementVisible" :lock-scroll="true" width="600px" top="5vh" custom-class="agreementFile" destroy-on-close>
            <el-image :src="item" v-for="(item, index) in agreementFile" :key="index" lazy></el-image>
        </el-dialog>
    </div>
</template>
<script>
import { s_setItem } from "@/common/cache"
/**已结束 */
export default {
	name: "over",
	data() {
		return {
			agreementVisible: false,
			agreementFile: [],
			mark: require("@/assets/imgs/icon/delta-icon.png")
		}
	},
	props: {
		list: {
			default: null,
			type: Object
		}
	},
	methods: {
		delel(item, index) {
			this.$confirm(`此操作将永久删除 <span class="color_000 bold">${item.reward.reward_name}</span> 任务，是否继续?`, '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/apply/${item.id}`,
					method: 'DELETE',
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.$parent.list.splice(index, 1)
						this.$parent.count.status2--
						this.$message.success(`${item.reward.reward_name}任务已删除`)
					}
				})
			})
		},
		runRecord(item) {
			let { user, create_time } = item
			user.status = '已结束'
			user.create_time = create_time
			s_setItem('_taskUserInfo', user)
			this.$router.push(`/employment/runRecord/${item.id}/${item.reward_id}/2`)
		},
		userSign(agr) {
			if(agr) {
				this.agreementVisible = true
				this.agreementFile = agr
			}else {
				this.$confirm(`用户未签署协议`, '提示', {
					confirmButtonText: '关 闭',
					cancelButtonText: '取 消',
					showCancelButton: false,
					type: 'warning'
				}).then(() => {

				})
			}
		}
	}
}
</script>
<style lang="less" scoped>
@import "../style/list.less";
.preview {
    text-decoration: underline;
}
</style>
