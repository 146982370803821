<template>
    <div class="list">
        <div class="item bgf mt_10" v-for="(item, index) in list" :key="index">
            <div class="bottom_link top flex_end">
                <div class="flex_col body" v-if="false">
                    <div class="flex_c mb_10">
                        <p class="fs_16 bold color_333 mr_15">{{ item.reward.reward_name }}</p>
                        <p class="fs_12 color_999">任务编号 {{ item.reward.reward_no }}</p>
                    </div>
                    <div class="flex">
                        <div class="use_tip color_333">
                            <span class="line color_red">{{ item.reward.settle_info }}</span>
                            <span class="line">{{ item.reward.price_type }}</span>
                            <span class="line">{{ item.reward.settle_type }}</span>
                            <span class="line">{{ item.reward.publish_user_name }}发布</span>
                        </div>
                        <div class="sub_color fs_12">接单人</div>
                    </div>
                </div>
                <div class="flex_end flex_1">
                    <div class="fs_12 wait flex_cen ml_20 mark_body">
                        <div class="color fs_20 name">{{ item.user && item.user.realname || '未知' }}</div>
                        <div class="mark_img">
                            <el-image class="img_cont" :src="mark"></el-image>
                        </div>
                    </div>
                    <div class="flex_c">
                        <div class="btn fs_14 center color_999" @click="finish(item, index)">结 束</div>
                        <div class="btn fs_14 center color check" @click="runRecord(item)">经营日志</div>
                        <div class="btn fs_14 center color check" @click="payRecord(item.id)">添加支出</div>
                        <router-link :to="`/employment/pay-list/${item.id}/${item.reward_id}/1`"
                            class="btn fs_14 center color check">支出记录</router-link>
                    </div>
                </div>
            </div>
            <div class="bottom fs_14">
                <el-row :gutter="15">
                    <el-col :span="7" class="flex_c">
                        <span class="label sub_color">接单企业：</span>
                        <!-- <span class="color_333 flex_1 one">{{ item.user_bussiness && item.user_bussiness.company_name || '-' }}</span> -->
                        <span class="color_333 flex_1 one">{{ '-' }}</span>
                    </el-col>
                    <el-col :span="7">
                        <span class="label sub_color">信用代码：</span>
                        <!-- <span class="color_333">{{ item.user_bussiness && item.user_bussiness.credit_code || '-' }}</span> -->
                        <span class="color_333">{{ '-' }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">收入总额：</span>
                        <span class="color_333">{{ item.income_total_amount }} 元</span>
                    </el-col>
                    <el-col :span="4">
                        <span class="label sub_color">收入次数：</span>
                        <span class="color_333">{{ item.income_total_amount_count }}次</span>
                    </el-col>
                </el-row>
                <el-row :gutter="15" class="mt_10">
                    <el-col :span="7">
                        <span class="label sub_color">联系电话：</span>
                        <span class="color_333">{{ item.user && item.user.mobile || '暂无' }}</span>
                    </el-col>
                    <el-col :span="7">
                        <span class="label sub_color">申请编号：</span>
                        <span class="color_333">{{ item.apply_no }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">申请时间：</span>
                        <span class="color_333">{{ item.create_time }}</span>
                    </el-col>
                    <el-col :span="4">
                        <p class="fs_14 color preview btn" v-if="item.agreement" @click="userSign(item.agreement)">用户签署协议
                        </p>
                        <p class="fs_14 info_color preview btn" v-else>该用户暂未签署协议</p>
                    </el-col>
                </el-row>
                <el-row :gutter="15" class="mt_10" v-if="item.bank_info">
                    <el-col :span="7">
                        <span class="label sub_color">银行卡号：</span>
                        <span class="color_333">{{ item.bank_info.account_number }}</span>
                    </el-col>
                    <el-col :span="7">
                        <span class="label sub_color">银行：</span>
                        <span class="color_333">{{ item.bank_info.account_bank }}</span>
                    </el-col>

                    <el-col :span="6">
                        <span class="label sub_color">支行：</span>
                        <span class="color_333">{{ item.bank_info.bank_name }}</span>
                    </el-col>
                    <el-col :span="4">
                        <!-- <el-image style="width:0px; height:0px;position: absolute;" :ref="`previewImg${index}`"
                            :preview-src-list="[item.bank_info.image]">
                        </el-image> -->
                        <p class="fs_14 color preview btn" @click="userSign([item.bank_info.image])">查看银行卡图片
                        </p>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
    <el-dialog v-model="agreementVisible" :lock-scroll="true" width="600px" top="5vh" custom-class="agreementFile"
        destroy-on-close>
        <el-image :src="item" v-for="(item, index) in agreementFile" :key="index" lazy></el-image>
    </el-dialog>
    <pay-msg :id="id" ref="paymsg" @init="init"></pay-msg>
</template>
<script>
import payMsg from "../dialog/pay-msg"
import { s_setItem } from "@/common/cache"
/** 进行中 */
export default {
	name: "conduct",
	components: { payMsg },
	data() {
		return {
			agreementVisible: false,
			agreementFile: [],
			id: null,
			mark: require("@/assets/imgs/icon/delta-icon.png")
		}
	},
	props: {
		list: {
			default: null,
			type: Object
		}
	},
	methods: {
		init() {
			this.$parent.init()
		},
		runRecord(item) {
			let { user, status_text, create_time } = item
			user.status = status_text
			user.create_time = create_time
			s_setItem('_taskUserInfo', user)
			this.$router.push(`/employment/runRecord/${item.id}/${item.reward_id}/1`)
		},
		finish(item, index) {
			this.$confirm(`是否结束 <span class="color_000 bold">${item.reward.reward_name}</span> 任务?`, '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/apply/finish/${item.id}`,
					method: 'POST',
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.$parent.list.splice(index, 1)
						this.$parent.count.status1--
						this.$parent.count.status2++
						this.$message.success(`${item.reward.reward_name}任务已结束`)
					}
				})
			})
		},
		payRecord(id) {
			this.id = id
			this.$refs.paymsg.dialogVisible = true
		},
		// viewBigPicture(index) {
		//     // this.$refs.previewImg.showViewer = true; //vue2写法第一种写法
		//     this.$refs[`previewImg${index}`].clickHandler();     //vue2写法第二种写法
		// },
		userSign(agr) {
			if (agr) {
				this.agreementVisible = true
				this.agreementFile = agr
			} else {
				this.$confirm(`用户未签署协议`, '提示', {
					confirmButtonText: '关 闭',
					cancelButtonText: '取 消',
					showCancelButton: false,
					type: 'warning'
				}).then(() => {

				})
			}
		}
	}
}
</script>
<style lang="less" scoped>
@import "../style/list.less";

.preview {
    text-decoration: underline;
}
</style>
