<template>
    <div class="list">
        <div class="item bgf mt_10" v-for="(item, index) in list" :key="index">
            <div class="bottom_link top flex_end">
                <div class="flex_col body">
                    <div class="flex_c mb_10">
                        <p class="fs_16 bold color_333 mr_15">{{ item.reward.reward_name }}</p>
                        <p class="fs_12 color_999">任务编号 {{ item.reward.reward_no }}</p>
                    </div>
                    <div class="flex">
                        <div class="use_tip color_333">
                            <span class="line color_red">{{ item.reward.settle_info }}</span>
                            <span class="line">{{ item.reward.price_type }}</span>
                            <span class="line">{{ item.reward.settle_type }}</span>
                            <span class="line">{{ item.reward.publish_user_name }}发布</span>
                        </div>
                        <div class="sub_color fs_12">申请人</div>
                    </div>
                </div>
                <div class="flex_end flex_1">
                    <div class="fs_12 wait flex_cen ml_20 mark_body">
                        <div class="color fs_20 name">{{ item.user&&item.user.realname || '未知' }}</div>
                        <div class="mark_img">
                            <el-image class="img_cont" :src="mark"></el-image>
                        </div>
                    </div>
                    <div class="flex_c">
                        <div class="btn fs_14 center color_999" @click="audit(item, index, -1)">拒 绝</div>
                        <div class="btn fs_14 center color check" @click="audit(item, index, 1)">通 过</div>
                    </div>
                </div>
            </div>
            <div class="bottom fs_14">
                <el-row :gutter="15">
                    <el-col :span="7" class="flex_c">
                        <span class="label sub_color">主体名称：</span>
                        <span class="color_333 flex_1 one">{{ item.user_bussiness && item.user_bussiness.company_name || '' }}</span>
                    </el-col>
                    <el-col :span="7">
                        <span class="label sub_color">信用代码：</span>
                        <span class="color_333">{{ item.user_bussiness && item.user_bussiness.credit_code || '' }}</span>
                    </el-col>
                    <el-col :span="7">
                        <span class="label sub_color">申请时间：</span>
                        <span class="color_333">{{ item.create_time_text }}</span>
                    </el-col>
                </el-row>
                <el-row :gutter="15" class="mt_10">
                    <el-col :span="7">
                        <span class="label sub_color">申请人电话：</span>
                        <span class="color_333">{{ item.user&&item.user.mobile || '暂无' }}</span>
                    </el-col>
                    <el-col :span="7">
                        <span class="label sub_color">申请编号：</span>
                        <span class="color_333">{{ item.apply_no }}</span>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
/** 待审核 */
export default {
	name: "checking",
	data() {
		return {
			mark: require("@/assets/imgs/icon/delta-icon.png")
		}
	},
	props: {
		list: {
			default: null,
			type: Object
		}
	},
	methods: {
		audit(item, index, status) {
			this.$confirm(`是否通过 <span class="color_000 bold">${ item.user&&item.user.realname || '未知' }</span> 的任务申请?`, '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/apply/check/${item.id}`,
					method: 'POST',
					data: { status },
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.$parent.list.splice(index, 1)
						this.$parent.count.status0 --
						if(status>0) {
							this.$parent.count.status1 ++
						}else {
							this.$parent.count.status_1 ++
						}
						this.$message.success(`已${status>0?'通过':'拒绝'} ${ item.user&&item.user.realname || '未知' } 的任务申请`)
					}
				})
			})
		}
	}
}
</script>
<style lang="less" scoped>
@import "../style/list.less";
</style>
